import {
  Helmet,
  PreStyledComponents,
  TickListItem,
  CardCentered,
  IconCare,
  IconLegal,
  IconCouncil,
  IconTick,
  Banner,
  Section,
  Container,
  Row,
  Box,
  Heading,
  YouTubeVideo,
  routesObject,
  ResourcesTile,
  IconSheets,
  Panel,
  Interfaces,
  Theme
} from "@life-without-barriers/react-components"
import { Site } from "@life-without-barriers/gatsby-common"
import React from "react"
import { graphql } from "gatsby"
import { withTheme, ThemeProps } from "styled-components"

import Layout from "../../../components/disability/Layout"
import {
  OurApproach,
  YourRights,
  DisabilityServicesAdvisoryCouncil,
  DiversityAndInclusion,
  OurDisabilityServices,
  AboriginalAndTorresStraitIslanderPeoples
} from "../../../components/disability/RelatedPagesContent"

const { GridWrapper, IconWrapper } = PreStyledComponents
const { disabilityTheme } = Theme

const ServicePanel = withTheme(
  ({
    heading,
    body,
    theme
  }: { heading: string; body: string } & ThemeProps<Interfaces.Theme>) => (
    <Panel background="bg-lwb-theme-xxx-light">
      <div className="flex">
        <div>
          <IconWrapper
            role="presentation"
            className="IconWrapper mr3"
            size="30"
            color={theme.light}
          >
            <IconTick aria-hidden height="13" color="white" />
          </IconWrapper>
        </div>
        <div>
          <Heading topMargin="none" size={4} className="color-lwb-black">
            {heading}
          </Heading>
          <p>{body}</p>
        </div>
      </div>
    </Panel>
  )
)

interface Props {
  location: { pathname: string }
  data: {
    site: Site
  }
}

const bannerBreadcrumbs = [routesObject.disabilityHome]

const iconSelector = (
  type: string,
  color: string,
  height?: string
): JSX.Element | undefined => {
  if (type === "care") {
    return <IconCare color={color} height={height} />
  }
  if (type === "legal") {
    return <IconLegal color={color} height={height} />
  }
  if (type === "council") {
    return <IconCouncil color={color} height={height} />
  }
  return undefined
}

const OurApproachPage = ({
  location,
  data: {
    site: { siteMetadata }
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Our approach to disability services | ${siteMetadata.title}`}
        description="Our approach to disability services"
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout>
        <Banner
          title="Our approach to disability services"
          breadcrumbs={bannerBreadcrumbs}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>
                What you can expect from Life Without Barriers
              </Heading>
              <p>
                You play the biggest role in designing your supports – that is
                why our services are tailored to you. With you at the centre of
                decision-making, we can support you while you live life your
                way.
              </p>
              <Heading size={3}>
                Your Life Without Barriers support team will:
              </Heading>
              <ul className="flex flex-wrap flex-row mt4 pl1 mb0">
                {[
                  "Understand and protect your rights",
                  "Treat you with respect",
                  "Support your choices",
                  "Be responsive when you need us to change your supports",
                  "Uphold the highest standards of professionalism and integrity",
                  "Be excited to work with you and get to know you!"
                ].map((text, i) => (
                  <TickListItem
                    key={i}
                    text={text}
                    className={i === 0 ? "w-90-ns" : "mt3 w-90-ns"}
                    textClasses="pt0 pt1-ns"
                  />
                ))}
              </ul>
            </Box>
          </Row>
          <Heading size={3}>Our Services are:</Heading>
          <Row className="flex-wrap mt4">
            <Box className="w-100 w-50-ns">
              <ServicePanel
                heading="Based on evidence of success"
                body="This means we’re always learning and adapting our services based on what works."
              />
            </Box>
            <Box className="w-100 w-50-ns mt3 mt0-ns">
              <ServicePanel
                heading="About you"
                body="In other words, we're 'person-centered'. Our services and supports should meet your needs."
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Row>
                  <Box className="flex flex-column flex-row-l flex-column-reverse justify-between">
                    <YouTubeVideo
                      videoId="FQE5nCfRUJA"
                      title="Life Without Barriers and Protecting Client Rights"
                      className="w-100 w-seven-twelfths-l"
                    />
                    <div className="w-100 w-third-l pb4 pb0-l overflow-hidden flex">
                      <div className="items-center self-center">
                        <Heading size={2}>Our commitment to you</Heading>
                        <p>
                          Watch and/or listen to this short video that explains
                          why providing high quality and safe support that
                          protects your rights is important to us.
                        </p>
                      </div>
                    </div>
                  </Box>
                </Row>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box className="w-100">
              <ResourcesTile
                className="mt2 mt3-ns lwb-shadow w-100"
                name="For more information"
                icon={<IconSheets height="25" color={disabilityTheme.medium} />}
                resources={[
                  {
                    name: "Department of Social Services - Standards for disability services",
                    reference:
                      "https://www.dss.gov.au/our-responsibilities/disability-and-carers/standards-and-quality-assurance/national-standards-for-disability-services"
                  },
                  {
                    name: "NDIS Quality and Safeguards Commission - NDIS practice standards",
                    reference:
                      "https://www.ndiscommission.gov.au/providers/registered-ndis-providers/provider-obligations-and-requirements/ndis-practice-standards"
                  },
                  {
                    name: "Complaints or feedback | Life Without Barriers",
                    reference: "/contact-us/complaints-or-feedback/",
                    rootedLink: true
                  }
                ]}
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <Heading size={2} className="tc color-lwb-theme-darker">
                  How we put YOU first
                </Heading>
                <GridWrapper className="mt4 flex-ns flex-wrap-ns">
                  <CardCentered
                    {...OurApproach}
                    selectIcon={iconSelector}
                    iconHeight="70"
                    icon="care"
                    actionLabel="Find out more"
                    rooted={true}
                  />
                  <CardCentered
                    {...YourRights}
                    selectIcon={iconSelector}
                    iconHeight="60"
                    icon="legal"
                    actionLabel="Find out more"
                  />
                  <CardCentered
                    {...DisabilityServicesAdvisoryCouncil}
                    selectIcon={iconSelector}
                    iconHeight="60"
                    icon="council"
                    actionLabel="Find out more"
                  />
                </GridWrapper>
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box>
                <Heading size={2} className="color-lwb-theme-darker tc">
                  Want to learn more?
                </Heading>
                <GridWrapper className="mt4 flex-ns flex-wrap-ns">
                  <CardCentered
                    {...AboriginalAndTorresStraitIslanderPeoples}
                    actionLabel="Find out more"
                    rooted={true}
                  />
                  <CardCentered
                    {...DiversityAndInclusion}
                    actionLabel="Find out more"
                    rooted={true}
                  />
                  <CardCentered
                    {...OurDisabilityServices}
                    actionLabel="Find out more"
                  />
                </GridWrapper>
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default OurApproachPage
